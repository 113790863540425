/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';

import { RichText } from 'prismic-reactjs';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import ProductItem from '../components/ProductItem';

import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
`;

const Intro = styled(Col)`
  text-align: center;
  margin: ${rem(50)} auto ${rem(75)};
  width: 100%;
  max-width: ${rem(500)};
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(680)};
    font-size: ${rem(20)};
  }

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
  }

  &:after {
    content: '';
    display: block;
    height: 4px;
    width: ${rem(60)};
    margin: ${rem(30)} auto 0;
    background-color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const Section = styled(Row)`
  margin-bottom: ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const GridItem = styled(Col)`
  width: 100%;
  margin-bottom: ${rem(42)};

  ${breakpoint('medium')} {
    width: 50%;
  }

  ${breakpoint('mediumlarge')} {
    width: 33.33%;
  }

  /* ${breakpoint('large')} {
    width: 25%;
  } */
`;

function Products({ data, pageContext }) {
  useLangRedirect(pageContext);

  const { products: page, allProducts: items } = data.prismic;

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      <Hero
        preHeading={page.hero_title && RichText.asText(page.title)}
        heading={
          page.hero_title
            ? RichText.asText(page.hero_title)
            : RichText.asText(page.title)
        }
        image={page.hero_imageSharp}
      >
        {page.hero_text && <RichText render={page.hero_text} />}
      </Hero>

      {page.content && page.content.length && RichText.asText(page.content) && (
        <Row>
          <Intro>
            <RichText render={page.content} />
          </Intro>
        </Row>
      )}

      <Container>
        <Section>
          {items.edges.map(({ node: project }) => (
            <GridItem key={project._meta.id}>
              <ProductItem data={project} />
            </GridItem>
          ))}
        </Section>
      </Container>
    </Layout>
  );
}

Products.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismic {
      products(uid: $uid, lang: $lang) {
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_text
        seo_description
        hero_title
        seo_meta_title
        title
        _meta {
          uid
          lang
        }
        content
      }
      allProducts(lang: $lang, sortBy: menu_order_DESC) {
        edges {
          node {
            short_description
            description
            featured_image
            featured_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            product_image
            product_imageSharp {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 620, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            seo_meta_title
            seo_description
            title
            _meta {
              id
              uid
              lang
              type
            }
            _linkType
          }
        }
        totalCount
      }
    }
  }
`;

export default Products;
